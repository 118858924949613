import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import InfoPanel from "../components/infoPanel"

import { graphql } from "gatsby"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      infoBoxes: null,
      infoPanels: null,
      blogPost: null,
    }
    this.renderInfoBoxes = this.renderInfoBoxes.bind(this)
    this.renderInfoPanels = this.renderInfoPanels.bind(this)
    this.renderBlogPost = this.renderBlogPost.bind(this)
  }
  render() {
    let infoBoxes = this.state.infoBoxes
    let infoPanels = this.state.infoPanels
    let blogPost = this.state.blogPost

    return (
      <Layout>
        {infoBoxes}
        {infoPanels}
        {blogPost}
      </Layout>
    )
  }
  componentDidMount() {
    this.renderInfoBoxes()
    this.renderInfoPanels()
    this.renderBlogPost()
  }

  renderInfoBoxes() {
    const infoBoxesData = this.props.data.allSanityInfoBox.nodes

    let infoBoxes = infoBoxesData.map(box => (
      <Hero
        key={box.title}
        title={box.title}
        info={box.info}
        buttonString={box.buttonString}
        buttonLink={box.buttonLink}
        image={box.image.asset.url}
      />
    ))
    this.setState({ infoBoxes })
  }

  renderInfoPanels() {
    const infoPanelsData = this.props.data.allSanityInfoPanel.nodes
    infoPanelsData.sort((a, b) => {
      return a.severity - b.severity
    })
    let infoPanels = infoPanelsData.map(panel => (
      <InfoPanel
        key={panel.title}
        title={panel.title ? panel.title : null}
        image={panel.image ? panel.image.asset.url : null}
        text={panel._rawText ? panel._rawText : null}
        linkText={panel.linkText ? panel.linkText : null}
        linkUrl={panel.linkUrl ? panel.linkUrl : null}
      />
    ))

    this.setState({ infoPanels })
  }

  renderBlogPost() {
    const allPosts = this.props.data.allSanityPost.nodes
    const blogPanel = allPosts[0]
    let blogPost = null

    if (allPosts.length > 0) {
      blogPost = (
        <InfoPanel
          key={blogPanel.title}
          image={false}
          title={blogPanel.title ? blogPanel.title : null}
          date={blogPanel._createdAt ? blogPanel._createdAt : null}
          text={blogPanel._rawBody[0] ? blogPanel._rawBody[0] : null}
          linkUrl="/blogg"
          linkText="Les mer"
        />
      )
    }
    this.setState({ blogPost })
  }
}

export default IndexPage

export const query = graphql`
  query {
    allSanityAuthor {
      nodes {
        name
      }
    }
    allSanityInfoBox(filter: { location: { eq: "homePage" } }) {
      nodes {
        title
        info
        buttonString
        buttonLink
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityInfoPanel(filter: { location: { eq: "homePage" } }) {
      nodes {
        title
        image {
          asset {
            url
          }
        }
        linkUrl
        linkText
        _rawText(resolveReferences: { maxDepth: 2 })
        severity
      }
    }

    allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        _createdAt
        title
        _rawBody(resolveReferences: { maxDepth: 2 })
        author {
          name
        }
      }
    }
  }
`
