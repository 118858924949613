import React from "react"
import { Link } from "gatsby"
import logo from "../images/tbnutrition-logo.png"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuItems: null,
      menuOpen: false,
      scrollDown: false,
    }
    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this)
    this.renderMenuItems = this.renderMenuItems.bind(this)
  }
  render() {
    let menuItems = this.state.menuItems

    return (
      <>
        <div className="header--adjust-height" />
        <header style={this.state.scrollDown ? { opacity: 0 } : { opacity: 1 }}>
          <div className="header">
            <Link
              className={
                "header__logo-link " +
                (this.state.menuOpen ? "header__logo-link--static" : "")
              }
              to="/"
            >
              <img
                className="header__logo"
                src={logo}
                alt="TB Nutrition logo"
              />
            </Link>

            <div
              id="burgerMenu"
              className={
                "burger-menu " +
                (this.state.menuOpen ? "burger-menu--open" : "")
              }
              onClick={e => this.toggleBurgerMenu(e)}
            >
              <div className="burger-menu-wrap">
                <div className="burger-menu__slice" />
              </div>
            </div>
            <nav
              className={
                "header__menu " +
                (this.state.menuOpen ? "header__menu--open" : "")
              }
            >
              <ul>{menuItems}</ul>
            </nav>
          </div>
        </header>
      </>
    )
  }
  renderMenuItems() {
    const menuLinks = this.props.menuItems
    let menuItems = menuLinks.map(link => (
      <li key={"headerlink: " + link.name}>
        <Link to={link.link} activeClassName="header__menu-link--active">
          {link.name}
        </Link>
      </li>
    ))
    this.setState({ menuItems })
  }

  toggleBurgerMenu(e) {
    this.setState({ menuOpen: !this.state.menuOpen })
    e.stopPropagation()
  }

  componentDidMount() {
    this.renderMenuItems()

    let previousScrollValue = 0

    if (typeof window !== undefined) {
      document.querySelector("main").addEventListener("click", () => {
        if (this.state.menuOpen) {
          this.setState({ menuOpen: !this.state.menuOpen })
        }
      })

      window.addEventListener("scroll", () => {
        let currentScrollValue = window.pageYOffset

        if (
          currentScrollValue > 0 &&
          currentScrollValue > previousScrollValue
        ) {
          previousScrollValue = currentScrollValue
          if (currentScrollValue >= 80 && !this.state.menuOpen) {
            this.setState({ scrollDown: true })
          }
        } else {
          previousScrollValue = currentScrollValue
          this.setState({ scrollDown: false })
        }
      })
    }
  }
}

export default Header
