import React from "react"
import HeroBox from "./heroBox"

const Hero = ({ image, title, info, buttonString, buttonLink }) => (
  <div className="hero">
    <div
      className="parallax"
      style={{
        backgroundImage: "url(" + image + ")",
      }}
    >
      <div className="hero__content">
        <HeroBox
          title={title}
          info={info}
          buttonString={buttonString}
          buttonLink={buttonLink}
        />
      </div>
    </div>
  </div>
)

export default Hero
