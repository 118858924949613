import React from "react"
import { Link } from "gatsby"
import { serializers } from "../js/blockContentSerializer"
const BlockContent = require("@sanity/block-content-to-react")

const InfoPanel = ({ image, title, text, linkText, linkUrl }) => (
  <>
    <div className="infopanel">
      <div className="infopanel__content">
        {image ? (
          <div className="infopanel__image-wrap">
            <div
              className="infopanel__image"
              style={{ backgroundImage: "url(" + image + ")" }}
              role="img"
              aria-label="Infopanel image"
            />
          </div>
        ) : null}

        <div
          className={
            "infopanel__text-content " +
            (image ? "" : "infopanel__text-content--noimage")
          }
        >
          {title ? <h2 className="infopanel__title">{title}</h2> : null}

          {text ? (
            <BlockContent blocks={text} serializers={serializers} />
          ) : null}

          {linkUrl ? (
            <Link className="infopanel__link" to={linkUrl}>
              {linkText ? linkText : "Link"}
            </Link>
          ) : null}
        </div>
      </div>
    </div>
    <hr />
  </>
)

export default InfoPanel
