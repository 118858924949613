// import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuItems: null,
      contactInfo: null,
      socialMedia: null,
    }
    this.renderContactInfo = this.renderContactInfo.bind(this)
    this.renderSocialMedia = this.renderSocialMedia.bind(this)
    this.renderMenuItems = this.renderMenuItems.bind(this)
  }
  render() {
    let menuItems = this.state.menuItems
    let socialMedia = this.state.socialMedia
    let contactInfo = this.state.contactInfo

    return (
      <footer className="footer-wrapper">
        <div className="footer">
          <div className="footer-column">
            <h3 className="footer-column__title">Innhold</h3>
            <ul className="footer-column__list">{menuItems}</ul>
          </div>

          <div className="footer-column">
            <h3 className="footer-column__title">Sosiale medier</h3>
            <ul className="footer-column__list">{socialMedia}</ul>
          </div>

          <div className="footer-column">
            <h3 className="footer-column__title">Kontakt</h3>
            <ul className="footer-column__list">{contactInfo}</ul>
          </div>
          <div className="wrapper">
            <p className="center-align padding-small">
              Design and development by{" "}
              <a className="footer-link" href="https://delineateco.com">
                delineate
              </a>
            </p>
          </div>
        </div>
      </footer>
    )
  }
  componentDidMount() {
    this.renderMenuItems()
    this.renderContactInfo()
    this.renderSocialMedia()
  }

  renderMenuItems() {
    const menuLinksData = this.props.menuLinks
    const pageLinksData = this.props.pageLinks

    let pageLinks = pageLinksData.map(link => (
      <li key={link.name}>
        <Link to={link.link}>{link.name}</Link>
      </li>
    ))

    let menuLinks = menuLinksData.map(link => (
      <li key={link.name}>
        <Link to={link.link}>{link.name}</Link>
      </li>
    ))

    let menuItems = pageLinks.concat(menuLinks)
    this.setState({ menuItems })
  }

  renderContactInfo() {
    const contactInfoData = this.props.contactInfo
    let contactInfo = contactInfoData.map(contactMedia => (
      <li key={contactMedia.title}>
        {contactMedia.content.includes("@") ? (
          <a href={"mailto:" + contactMedia.content}>{contactMedia.content}</a>
        ) : (
          contactMedia.title + ": " + contactMedia.content
        )}
      </li>
    ))

    this.setState({ contactInfo })
  }

  renderSocialMedia() {
    const socialMediaData = this.props.socialMedia
    let socialMedia = socialMediaData.map(socialMedia => (
      <li key={socialMedia.title}>
        {socialMedia.title === "Facebook" ? (
          <a href={socialMedia.link}>
            <i className="fab fa-facebook-square" />
            {socialMedia.title}
          </a>
        ) : null}
        {socialMedia.title === "LinkedIn" ? (
          <a href={socialMedia.link}>
            <i className="fab fa-linkedin" />
            {socialMedia.title}
          </a>
        ) : null}
        {socialMedia.title === "Instagram" ? (
          <a href={socialMedia.link}>
            <i className="fab fa-instagram" />
            {socialMedia.title}
          </a>
        ) : null}
      </li>
    ))

    this.setState({ socialMedia })
  }
}

export default Footer
