import React from "react"

const HeroBox = ({ title, info, buttonString, buttonLink }) => (
  <div className="hero-box">
    <h2 className="hero-box__headline">{title}</h2>
    <div className="hero-box__text">{info}</div>
    <div className="hero-box__button-wrap">
      <a href={buttonLink ? buttonLink : "/"} className="button">
        {buttonString}
      </a>
    </div>
  </div>
)

export default HeroBox
