/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "./seo"

import Header from "./header"
import Footer from "./footer"
import "../js/chaportChat"
import "../styles/main.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            menuItems {
              name
              link
            }
            pageLinks {
              name
              link
            }
          }
        }
        allSanityContactInfo {
          nodes {
            title
            content
          }
        }
        allSanitySocialMedia {
          nodes {
            title
            link
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO title={data.site.title} />

        <Header
          siteTitle={data.site.siteMetadata.title}
          menuItems={data.site.siteMetadata.menuItems}
        />
        <main>{children}</main>
        <Footer
          menuLinks={data.site.siteMetadata.menuItems}
          pageLinks={data.site.siteMetadata.pageLinks}
          contactInfo={data.allSanityContactInfo.nodes}
          socialMedia={data.allSanitySocialMedia.nodes}
        />
      </>
    )}
  />
)

export default Layout
