import React from "react"

const blockSerializer = props => {
  const style = props.node.style || "normal"

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, "")
    return (
      <span
        className={`my-heading level-${level}`}
        role={"h" + level}
        aria-label="heading"
      >
        {props.children}
      </span>
    )
  }

  return style === "blockquote" ? (
    <blockquote>{props.children}</blockquote>
  ) : (
    <p>{props.children}</p>
  )
}

const codeSerializer = props => {
  return (
    <pre data-language={props.node.language}>
      <code>{props.node.code}</code>
    </pre>
  )
}

const imageSerializer = props => {
  return (
    <img
      className="blog-post__image"
      src={props.node.asset.url}
      alt="Blogpost"
    />
  )
}

const listItem = props => {
  return <li className="list-item">{props.children}</li>
}

export const serializers = {
  types: {
    code: codeSerializer,
    block: blockSerializer,
    image: imageSerializer,
  },
  listItem: listItem,
}
